<template>
  <v-dialog v-model="campaignMessagesListModal">
    <v-card>
      <v-data-table
      :headers="tableColumns"
      :items="campaign_messages"
      :page="current_page"
      :loading="loading"
      :server-items-length="totalListTable"
      :hide-default-footer="true"
      >

      <!-- ack_status column header -->
      <template v-slot:header.ack_status="{ header }">
          <v-icon :color="'secondary'">
            {{ icons.mdiWhatsapp }}
          </v-icon>
        </template>

        <!-- Ack-status items-->
        <template #[`item.ack_status`]="{ item }">
          <span class="" v-if="message_status === 'SENDED'" >
            <button
              class="mr-3"
              @click="checkStatusAck(item.id)"
              :disabled="
                item.ack_status === 'not check again' ||
                item.ack_status === 'read' ||
                !item.ack_status.length
              "
            >
              <!-- <v-icon class="loader"></v-icon> -->
              <v-icon
                :color="'primary'"
                :disabled="
                  item.ack_status === 'not check again' ||
                  item.ack_status === 'read' ||
                  !item.ack_status.length
                "
              >
                {{ icons.mdiReloadAlert }}
              </v-icon>
            </button>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class=""
                  :color="resolveAckVariant(item.ack_status)"
                  small
                  dark
                  v-bind="attrs"
                  v-on="on"
                  >{{ item.ack_status === "send" ? icons.mdiCheck : icons.mdiCheckAll }}
                </v-icon>
              </template>
              <span>{{ translateAck(item.ack_status) }}</span>
            </v-tooltip>
          </span>
        </template>

        <!-- message -->
        <template #[`item.message`]="{ item }">
          <span v-if="item.message.length <= 175">{{ item.message }}</span>
          <span v-else>
            {{ item.message.substring(0, 175) + "..." }}
            <v-btn icon x-small @click="showCompleteMsg(item.message)">
              <v-icon color="primary">{{ icons.mdiPlus }}</v-icon>
            </v-btn>
          </span>
          <div v-for="(file, idx) in item.files" :key="idx">
            <span>{{ file.name }}</span>
            <v-btn
              icon
              :href="file.url"
              target="_blank"
            >
              <v-icon color="primary">
                {{ icons.mdiDownload }}
              </v-icon>
            </v-btn>
          </div>
        </template>

        <template #[`item.date`]="{ item }">
          <span class="text-capitalize font-weight-semibold text--primary">{{
            item.send_time != null ? item.send_time.slice(0, 10) : "-"
          }}</span>
        </template>

        <template #[`item.time`]="{ item }">
          <span class="text-capitalize font-weight-semibold text--primary">{{
            item.send_time != null ? item.send_time.slice(11, 16) : "-"
          }}</span>
        </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="close()">
        CERRAR
      </v-btn>
    </v-card-actions>
    </v-card>

    <template>
      <v-dialog v-model="long_msg_modal" max-width="500">
        <v-card>
          <v-card-text>
            {{ long_msg }}
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api';
import {
  mdiPlus,
  mdiCheck,
  mdiCheckAll,
  mdiReloadAlert,
  mdiWhatsapp,
  mdiDownload
} from "@mdi/js";

export default {
  data: () => {
    return {
      campaign_messages: [],
      current_page: 1,
      campaignMessagesListModal: false,
      long_msg: "",
      long_msg_modal: false,
      message_status: null
    }
  },
  methods: {
    // async getFailedMessagesCause() {
    //   try {
    //     const data = {
    //       page_size: this.page_size,
    //       page: this.current_page,
    //     }
    //     const item = await this.$api.getFailedMessagesCause(this.campaign_id, data)
    //     this.campaign_messages = item.data.results
      
    //     this.loading = false
    //     this.totalListTable = item.data.count
    //     this.campaignMessagesListModal = true
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },
    async openModal(messages, status) {
        // console.log(item)
      this.campaign_messages = messages
      this.message_status = status
      this.totalListTable = messages.length
      this.campaignMessagesListModal = true
    },

    translateAck(value) {
      const translation = {
        send: "Enviado",
        received: "Recibido",
        read: "Leído",
      };
      return translation[value] ?? "Leído";
    },


    close(){
      this.current_page = 1
      this.campaign_messages = []
      this.totalListTable = 0
      this.campaignMessagesListModal = false
    },
    showCompleteMsg(message) {
      this.long_msg = message;
      this.long_msg_modal = true;
    },

    async checkStatusAck(item) {
      this.loading = true;
      // this.loadIcon = true
      try {
        const response = await this.$api.checkStatusAck(item);
        this.campaign_messages.forEach((element) =>
          element.id === item ? (element.ack_status = response) : false
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false; /* this.loadIcon = false; */
      }
    },
  },
  setup() {
      const loading = ref(false)
      const totalListTable = ref(0)

      const tableColumns = [
        {
          text: "STATUS",
          value: "ack_status",
          align: "center",
          sortable: false,
          width: "90px",
        },
        { text: "MENSAJE", value: "message", align: 'start', sortable: false},
        { text: "FECHA", value: "date", align: "center", sortable: false },
        { text: "HORA", value: "time", align: "center", sortable: false },
      ]

      const resolveAckVariant = (ack_status) => {
        if (ack_status === "send" || ack_status === "received") return "secondary";
        if (ack_status === "read" || ack_status === "not check again") return "accent";

        return "accent";
      };

      return {
        loading,
        totalListTable,
        tableColumns,
        resolveAckVariant,
        icons: {
        mdiPlus,
        mdiCheck,
        mdiCheckAll,
        mdiReloadAlert,
        mdiWhatsapp,
        mdiDownload
        }
      }
    }
}
</script>